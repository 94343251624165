import Util from "../js/util.js";
import SystemDemo from "../js/systemdemo.js";
import { GetProfile } from "../js/apis.js";

var ribbon = {
  //这个函数在整个wps加载项中是第一个执行的
  OnAddinLoad: async function (ribbonUI) {
    if (typeof wps.ribbonUI != "object") {
      wps.ribbonUI = ribbonUI;
    }

    if (typeof wps.Enum != "object") {
      // 如果没有内置枚举值
      wps.Enum = Util.WPS_Enum;
    }

    //这几个导出函数是给外部业务系统调用的
    window.openOfficeFileFromSystemDemo =
      SystemDemo.openOfficeFileFromSystemDemo;
    window.InvokeFromSystemDemo = SystemDemo.InvokeFromSystemDemo;
    try {
      const data = await GetProfile();
      console.log(data);
    } catch (e) {
      console.log(334, e);
    }

    // Todo: 获取profile，检查是否登录，设置flag
    wps.PluginStorage.setItem("Token", ""); //往PluginStorage中设置一个标记，用于控制两个按钮的置灰

    wps.ribbonUI.InvalidateControl("btnLogin");
    wps.ribbonUI.InvalidateControl("btnAcc");
    wps.ribbonUI.InvalidateControl("btnLogout");
    wps.ribbonUI.InvalidateControl("btnLoan");
    wps.ribbonUI.InvalidateControl("btnTask");

    return true;
  },

  OnAction: function (control) {
    const eleId = control.Id;
    switch (eleId) {
      case "btnIsEnbable": {
        //通知wps刷新以下几个按饰的状态
        wps.ribbonUI.InvalidateControl("btnLogin");
        wps.ribbonUI.InvalidateControl("btnAcc");
        wps.ribbonUI.InvalidateControl("btnLogout");
        wps.ribbonUI.InvalidateControl("btnLoan");
        wps.ribbonUI.InvalidateControl("btnTask");
        //wps.ribbonUI.Invalidate(); 这行代码打开则是刷新所有的按钮状态
        break;
      }
      case "btnLogout":
        wps.PluginStorage.setItem("Token", "");

        wps.ribbonUI.InvalidateControl("btnLogin");
        wps.ribbonUI.InvalidateControl("btnAcc");
        wps.ribbonUI.InvalidateControl("btnLogout");
        wps.ribbonUI.InvalidateControl("btnLoan");
        wps.ribbonUI.InvalidateControl("btnTask");
        break;
      case "btnLogin":
        wps.ShowDialog(
          Util.GetUrlPath() + "/dialog",
          "登录",
          400 * window.devicePixelRatio,
          280 * window.devicePixelRatio,
          true,
        );
        break;
      case "btnLoan":
        wps.ShowDialog(
          Util.GetUrlPath() + "/loan",
          "资金测算工具",
          400 * window.devicePixelRatio,
          280 * window.devicePixelRatio,
          true,
        );
        break;
      case "btnTask":
        let tsId = wps.PluginStorage.getItem("taskpane_id");
        if (!tsId) {
          let tskpane = wps.CreateTaskPane(Util.GetUrlPath() + "/task");
          let id = tskpane.ID;
          wps.PluginStorage.setItem("taskpane_id", id);
          tskpane.Visible = true;
        } else {
          let tskpane = wps.GetTaskPane(tsId);
          tskpane.Visible = !tskpane.Visible;
        }

        break;
      default:
        break;
    }

    return " ";
  },
  GetImage: function (control) {
    const eleId = control.Id;
    switch (eleId) {
      case "btnLogin":
        return "images/login.svg";
      case "btnAcc":
        return "images/account.svg";
      case "btnLogout":
        return "images/logout.svg";
      case "btnLoan":
        return "images/loan.svg";
      case "btnTask":
        return "images/tasks.svg";
      default:
        break;
    }
    return "images/newFromTemp.svg";
  },
  OnGetEnabled: function (control) {
    const eleId = control.Id;
    let token = wps.PluginStorage.getItem("Token");
    let isLogin = token !== "";
    switch (eleId) {
      case "btnLogin":
        return !isLogin;
      case "btnAcc":
        return isLogin;
      case "btnLogout":
        return isLogin;
      case "btnLoan":
        return isLogin;
      case "btnTask":
        return isLogin;
      default:
        break;
    }
    return true;
  },
  OnGetVisible: function (control) {
    const eleId = control.Id;
    let token = wps.PluginStorage.getItem("Token");
    let isLogin = token !== "";
    switch (eleId) {
      case "btnLogin":
        return !isLogin;
      case "btnAcc":
        return isLogin;
      case "btnLogout":
        return isLogin;
      case "btnLoan":
        return isLogin;
      case "btnTask":
        return isLogin;
      default:
        break;
    }
    return true;
  },
  OnGetLabel: function (control) {
    return "";
  },
  OnNewDocumentApiEvent: function (doc) {
    alert("新建文件事件响应，取文件名: " + doc.Name);
  },
};

export default ribbon;
