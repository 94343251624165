import React, { useState } from "react";
import "./dialog.css";
import { Avatar, List, message, Button, Spin } from "antd";
import { useRequest, useMount } from "ahooks";
import { GetTasks } from "../js/apis";

const Task = () => {
  const [items, setItems] = useState([]);
  const dataReq = useRequest(GetTasks, {
    manual: true,
    onSuccess: (ret, params) => {
      const { data } = ret;
      if (data.success) {
        setItems(data.data);
      }
    },
    onError: (e, params) => {
      message.error(e.msg);
    },
  });

  useMount(() => {
    dataReq.run({});
  });

  const showData = () => {
    let rat = (Math.random() + 1).toString(36).substring(7);
    var bt = wps.Application.Workbooks.Open(
      `https://ci.liyinjia.com/api/templates/FundingMeasurement.xltm?${rat}`,
    );
    bt.Worksheets.Item("计划信息").Activate();
    const data = [
      "X选6号5期-12",
      7000,
      "12/15/2023",
      "3/21/2024",
      "3/21/2024",
      "X选3+6+20",
      2660,
      494.56,
      372403.65,
      2288,
      90.91,
    ];
    var sht = bt.Worksheets.Item("计划信息");
    for (var i = 0; i < 8; i++) {
      let rg = sht.ListObjects.Item("tblSet1").ListRows.Add().Range;
      rg.Value2 = data;
    }

    sht.ListObjects.Item("tblSet1").ListRows.Item(1).Delete();

    for (let i = 0; i < 50; i++) {
      let rg = sht.ListObjects.Item("tblSet2").ListRows.Add().Range;
      rg.Value2 = data;
    }
    sht.ListObjects.Item("tblSet2").ListRows.Item(1).Delete();

    bt.Worksheets.Item("附-还款计划").Activate();

    var data2 = [
      "3/21/2024",
      "X选3+6+20",
      "3/20/2024",
      "X选20号4期-3",
      "dd钱优选",
      175166.61,
      11610.03,
      61501.45,
      3888.46,
      119.0,
      65389.91,
    ];

    var sht2 = bt.Worksheets.Item("附-还款计划");
    for (let i = 0; i < 50; i++) {
      let rg = sht2.ListObjects.Item("tblSet3").ListRows.Add().Range;
      rg.Value2 = data2;
    }

    sht2.ListObjects.Item("tblSet3").ListRows.Item(1).Delete();

    for (let i = 0; i < 50; i++) {
      let rg = sht2.ListObjects.Item("tblSet4").ListRows.Add().Range;
      rg.Value2 = data2;
    }
    sht2.ListObjects.Item("tblSet4").ListRows.Item(1).Delete();

    window.close();
  };

  const showDataOutter = async () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          showData();
          resolve();
        } catch (err) {
          reject(err);
        }
      }, 1000);
    });
  };

  const showDataReq = useRequest(showDataOutter, {
    manual: true,
    onSuccess: (ret, params) => {},
    onError: (e, params) => {
      // message.error(e.msg);
    },
  });

  return (
    <div class="global">
      <div class="wrapper">
        <Spin spinning={showDataReq.loading}>
          <List
            loading={dataReq.loading}
            dataSource={items}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  avatar={<Avatar src="/images/1.svg" />}
                  title={item.name}
                  description={item.args}
                />
                <div>
                  {
                    <Button
                      size="small"
                      onClick={() => {
                        showDataReq.run();
                      }}
                    >
                      加载
                    </Button>
                  }
                </div>
              </List.Item>
            )}
          />
        </Spin>
      </div>
    </div>
  );
};

export default Task;
