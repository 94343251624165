import React from "react";
import "./dialog.css";
import { Input, message, Space, Button, Form } from "antd";
import { useRequest } from "ahooks";
import { DoLogin } from "../js/apis";

const Dialog = () => {
  const [form] = Form.useForm();

  const loginReq = useRequest(DoLogin, {
    manual: true,
    onSuccess: (ret, params) => {
      const { data } = ret;
      message.info(JSON.stringify(data.token));

      wps.PluginStorage.setItem("Token", data.token);

      wps.ribbonUI.InvalidateControl("btnLogin");
      wps.ribbonUI.InvalidateControl("btnAcc");
      wps.ribbonUI.InvalidateControl("btnLogout");
      wps.ribbonUI.InvalidateControl("btnLoan");
      wps.ribbonUI.InvalidateControl("btnTask");
      window.close();
    },
    onError: (e, params) => {
      message.error(e.msg);
    },
  });

  const onFinish = () => {
    form.validateFields().then((values) => {
      loginReq.run(values);
      // form.resetFields();
    });
  };

  return (
    <div class="global">
      <div class="wrapper">
        <Form form={form} style={{ width: "350px" }} onFinish={onFinish}>
          <Form.Item
            label="用户名"
            name="username"
            rules={[{ required: true, message: "请输入用户名" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="密码"
            name="password"
            rules={[{ required: true, message: "请输入密码" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <div style={{ textAlign: "right" }}>
              <Space>
                <Button
                  loading={loginReq.loading}
                  type="primary"
                  onClick={onFinish}
                >
                  登录
                </Button>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Dialog;
