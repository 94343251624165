import React from "react";
import "./dialog.css";
import { Input, message, Space, Button, Form } from "antd";
import { useRequest } from "ahooks";

const Loan = () => {
  const [form] = Form.useForm();

  const loginReq = useRequest(() => {}, {
    manual: true,
    onSuccess: (ret, params) => {
      // const { data } = ret;
      window.close();
    },
    onError: (e, params) => {
      message.error(e.msg);
    },
  });

  const onFinish = () => {
    form.validateFields().then((values) => {
      loginReq.run(values);
      // form.resetFields();
    });
  };

  return (
    <div class="global">
      <div class="wrapper">
        <Form form={form} style={{ width: "350px" }} onFinish={onFinish}>
          <Form.Item
            label="机构"
            name="org"
            rules={[{ required: true, message: "请输入机构名" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="状态"
            name="status"
            rules={[{ required: true, message: "状态" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <div style={{ textAlign: "right" }}>
              <Space>
                <Button type="primary" onClick={onFinish}>
                  提交
                </Button>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Loan;
