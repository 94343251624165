import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import App from "./components/app";
import AxiosProvider from "./js/axiosprovider";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <AxiosProvider>
    <App />
  </AxiosProvider>,
);
