import axiosGlobal from "axios";
import React, { createContext, useMemo } from "react";

axiosGlobal.defaults.withCredentials = true;

const codeMessage = {
  400: "The API request is invalid or improperly formed. ",
  401: "The access token you're using is either expired or invalid.",
  403: "The requested operation is forbidden and cannot be completed.",
  404: "The requested operation failed because a resource associated with the request could not be found.",
  405: "The HTTP method associated with the request is not supported.",
  406: "An invalid format is specified in the request",
  410: "The request failed because the resource associated with the request has been deleted",
  422: "The data is unable to be processed ",
  500: "The request failed due to an internal error.",
  502: "The request failed due to a gateway error.",
  503: "We encountered an internal error. Please try again.",
  504: "The request couldn't be serviced due to some failure within the internal stack. Try again later.",
};

const baseURL = "";

const config = {
  baseURL: baseURL,
  timeout: 30000,
  responseType: "json",
  withCredentials: true,
  Headers: {
    "Content-Type": "applicatioon/json",
  },
};

export const AxiosContext = createContext(axiosGlobal.create(config));

export const GetFiAxios = () => {
  const axios = axiosGlobal.create(config);
  axios.interceptors.request.use((config) => {
    // const token = wps.PluginStorage.getItem("Token");
    // config.Headers["Authorization"] = `Bearer ${token}`
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      const { response } = err;
      if (!response) {
        return Promise.reject(err);
      }

      const errortext =
        (response.data && response.data.msg) || codeMessage[response.status];
      return Promise.reject({ code: response.status, msg: errortext });
    },
  );

  return axios;
};

const AxiosProvider = ({ children }) => {
  const axios = useMemo(() => {
    const axios = axiosGlobal.create(config);
    axios.interceptors.request.use((config) => {
      const token = wps.PluginStorage.getItem("Token");
      config.Headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        const { response } = err;
        if (!response) {
          return Promise.reject(err);
        }

        const errortext =
          (response.data && response.data.msg) || codeMessage[response.status];
        return Promise.reject({ code: response.status, msg: errortext });
      },
    );

    return axios;
  }, []);

  return (
    <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
  );
};

export default AxiosProvider;
