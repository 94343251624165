import React from "react";
import ribbon from "./ribbon";
import Dialog from "./dialog";
import Task from "./task";
import Loan from "./loan";
import Home from "./home";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const App = () => {
  window.ribbon = ribbon;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dialog" element={<Dialog />} />
        <Route path="/loan" element={<Loan />} />
        <Route path="/task" element={<Task />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
