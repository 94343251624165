import { GetFiAxios } from "./axiosprovider";
import { stringify } from "querystring";

export const GetProfile = async (param) => {
  const axios = GetFiAxios();
  return axios({
    url: `/api/auth/profile${stringify(param)}`,
    method: "GET",
    withCredentials: true,
  });
};

export const GetTasks = async (param) => {
  const axios = GetFiAxios();
  const token = wps.PluginStorage.getItem("Token");
  return axios({
    url: `/api/auth/tasks${stringify(param)}`,
    method: "GET",
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const DoLogin = async (data) => {
  const axios = GetFiAxios();
  return axios({
    url: `/api/login`,
    method: "POST",
    data: JSON.stringify(data),
    withCredentials: true,
  });
};
